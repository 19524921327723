<template>
  <div class="teacher">
    <div class="t-item" v-for="(item, index) of detail.teachers" :key="index">
      <div class="cover">
        <img :src="item.headPortrait" :alt="item.teacherName">
      </div>
      <div class="t-int">
        <div class="t-name">
          <span>{{item.teacherName}}</span>
          <p class="t-tag">主讲：{{item.content}}</p>
        </div>
        <p class="t-int-content">{{item.introduction}}</p>
      </div>
    </div>
    <van-empty v-if='detail.teachers.length === 0' description="暂无数据" />
  </div>
  
</template>

<script>
export default {
  name: "TeacherIntroduct",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="less" scoped>
.teacher{
  width:94%;
  margin:0 3% 0.4rem 3%;
  
  padding:0.2rem 0;
}
.t-item {
  background:#fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-bottom: 0.4rem;
}
.t-item .cover{
  width: 100%;
  height: 1.6rem;
  overflow: hidden;
  margin-right: 18px;
}
.cover img{
  display: block;
  width: 1.4rem;
  height:1.4rem;
  border-radius: 50%;
  margin:0.2rem auto;
}
.t-int .t-name{
  color: #FF3F47;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 0.6rem;
  margin:0.15rem auto;
}

.t-int .t-tag{
  height: 0.3rem;
  line-height: 0.3rem;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  margin:0.05rem 0;
  
}
.t-int .t-int-content{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 0.5rem;
  padding:0 0.3rem 0.3rem;
  text-align: left;
}
</style>