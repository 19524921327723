<template>
  <div class="tabs">
    <template v-for="(item, index) of list" >
      <div
        :key="index" 
        v-if="item.state"
        class="tabs-item" 
        @click="onChange(item)"
        :class="{
          'tabs-item-active': item.key === actived,
          'tabs-item-active-next': item.key === (actived + 1),
          'tabs-item-active-prev': item.key === (actived - 1)
          }">
        <span>{{item.label}}</span>
      </div>
    </template>
  </div>
  <!-- <div class="tabs">
    
    <template v-for="(item, index) of list" >
      <div
        :key="index" 
        v-if="item.state"
        class="tabs-item" 
        @click="onChange(item)"
        :class="{
          'tabs-item-active': item.key === actived,
          'tabs-item-active-next': item.key === (actived + 1),
          'tabs-item-active-prev': item.key === (actived - 1)
          }">
        <span>{{item.label}}</span>
      </div>
    </template>
    
    
  </div> -->
</template>

<script>
export default {
  name: "Tabs",
  props:{
    actived: {
      default: 0,
      type:Number
    },
    list: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  methods: {
    onChange(val = {}) {
      this.$emit('onChange', val)
    }
  }
}
</script>

<style lang="less" scoped>
.tabs{
  display: flex;
  font-size: 15px;
  display: flex;
  justify-content: space-evenly;
  height:1.1rem;
  background: #fff;
  
}
.tabs-item{
  display: inline-block;
  height: 0.7rem;
  line-height: 0.7rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #999;
  margin:0.2rem 0;
}
.tabs-item-active{
  font-weight: bold;
  color:#333333;
  border-bottom:2px #FF3F47 solid;
}

</style>