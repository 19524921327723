<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-03-07 09:48:04
 * @LastEditors  : BigBigger
-->
<!--班级选择-->
<template>
  <div>
    <div class="top">
      <div class="top-left">
        <div class="title">{{detail.courseGroupName}}</div>
        <div class="price">￥{{detail.currentPrice}} 
          <span class="discount" v-if="discount > 0">（总价优惠{{discount}}元）</span>
        </div>
      </div>
      <div class="top-right" @click="classOnClose">
        <img src="@/assets/images/icon/icon1.png">
      </div>
    </div>
    
    <div class="class-list">
    <van-list>
        <!-- 内容 -->
        <!-- <div class="classList" v-for="(item, index) of detail.courseDetailClassManageWebVos" :key="index">
          <div class="classInfo">
            <div class="info-content">
              <div class="infoList">
                <span>{{item.name}}</span>
                <div class="class-item">
                  <template v-for="(info, index) of item.courseDetailFlightManageWebVoList">
                    <div :key="index" @click="selectItem(info)">
                      <p class="name" :class="{'active' : checkedId === info.id }">{{info.name}}{{info.startTim}}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="classList" v-for="(item1, index1) of detail.courseDetailClassManageWebVos" :key="index1">
          <div class="classInfo">
            <div class="info-content">
              <div class="infoList">
                <span>{{item1.name}}</span>
                <div class="class-item">
                  <template v-for="(item2, index2) of item1.courseDetailFlightManageWebVoList">
                    <div :key="index2" @click="selectItem(item2, item1.id)">
                      <p class="name" :class="{active: isSelected(item1.id, item2.id)}">{{item2.name}}：{{item2.startTime}}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </van-list>
      <div class="sure-btn">
        <span @click="confirm">确定</span>
      </div>
      <van-empty style="background:#fff;" v-if="!detail.courseDetailClassManageWebVos" description="暂无数据" />
      
      
    </div>
  </div>
</template>
<script>
export default {
  name:'selectJob',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      classList: [],
      selectedClass: {},
      discount: 0,  
    }
  },
  computed: {
    isSelected() {
      return (id1, id2) => {
        return this.selectedClass[id1] && this.selectedClass[id1].id === id2
      }
    }
  },
  mounted() {
    if(this.detail.whetheHaveClassManager===1){
      this.selectedClass = this.initData(this.detail.courseDetailClassManageWebVos)
    }
    this.discount = this.detail.originalPrice - this.detail.currentPrice
  },
  methods:{ 
    initData(courseDetailClassManageWebVos = []) {
      let selectObj = new Object();
      if(courseDetailClassManageWebVos){
        courseDetailClassManageWebVos.forEach(item => {
          if(item.courseDetailFlightManageWebVoList && item.courseDetailFlightManageWebVoList.length > 0) {
            selectObj[item.id] = item.courseDetailFlightManageWebVoList[0];
          } else {
            selectObj[item.id] = null;
          }
        })
        return selectObj;
      }
    },
    selectItem(item2, item1Id) {
      this.selectedClass[item1Id] = item2;
    },
    confirm() {
      let arr = []
      for(let item in  this.selectedClass) {
        arr.push(this.selectedClass[item].id)
      }
      this.$store.dispatch('setOrderInfo',{
        classInfo: arr,

      })
      //console.log(">>", arr);
      this.$emit('getClassList',arr)
      this.$emit('classOnClose',false)
      
    },
    classOnClose(){
      this.$emit('classOnClose',false)
    }



  }
  
}
</script>
<style lang="less" scoped>
.top{
  position: fixed;
  top:40%;
  width:86%;
  height:1.6rem;
  background:#fff;
  display: flex;
  justify-content: space-between;
  padding:10px 7% 15px 7%;
  border-bottom: 1px solid #efefef;
  align-items: center;
  .top-right{
    width:0.3rem;
    height:0.3rem;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    img{
      width:100%;
      height:100%
    }
  }
  .title{
    line-height: 0.52rem;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 8px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .price{
    height:  0.4rem;
    line-height:  0.4rem;
    color: #FF3F47;
    font-size: 16px;
    font-weight: bold;
  }
  .discount{
    font-weight: normal;
  }
  
}
.class-list{
  padding:1.8rem 40px 60px 40px;
  
  
}
.classList .classInfo .infoList{
  font-size: 13px;
  line-height: 0.5rem;
}
.classList .classInfo .infoList span{
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.infoList{
  margin-top:25px;
}
.infoList .class-item p{
  width:100%;
  height:38px;
  line-height: 38px;
  text-align: center;
  background: #F2F2F2;
  border-radius: 10px;
  margin-top:15px;
  color: #333333;
}
.infoList .class-item .active{
  background: #FDEFEF;
  border: 1px solid #FF7773;
  color:#FF3F47;
}
.sure-btn{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  margin: 0 auto;
  background:#fff;
}
.sure-btn span{
  display: block;
  height: 35px;
  line-height: 35px;
  background: linear-gradient(90deg, #F15F32, #F13232);
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin:0 40px;
}
.van-popup__close-icon{
  position: fixed;
  right: 0;
  top:0;
}
</style>