<template>
  <div class="c-in">
    <img v-for="(item, index) of introductionImgList" :key="index" :src="item" alt="课程介绍">
    <van-empty v-if='!introductionImgList || introductionImgList.length === 0' description="暂无数据" />
  </div>
</template>

<script>
export default {
  name: 'CourseIntroduct',
  data() {
    return {
      introductionImgList:[],
    }
  },
  props:{
    detail:{
      type:Object,
      default: null,
      
    }
  },
  mounted(){
    if(this.detail.introductionImg) {
      this.introductionImgList = this.detail.introductionImg.split(",")
    } else {
      this.introductionImgList = []
    }
  }
}
</script>

<style lang="less" scoped>
.c-in{
  width: 100%;
  padding: 10px 0;
  img{
    width: 100%;
    display: block;
  }
}
</style>