<template>
  <div class="teacher-box" @click.stop v-if="detail">
    <div class="title">
      <div class="icon-box"><img src="@/assets/images/h5-icon/icon-5.png"></div>
      <p>购课咨询</p>
      <div class="close" @click="onClose">
        <img src="@/assets/images/h5-icon/close.png">
      </div>
    </div>
    <div class="consultative">
      <img :src="detail.consultingQrCode">
      <p>咨询老师：{{detail.consultingTeacher}}</p>
      <div class="phone">
        <span>微信：</span><p id="copy">{{detail.consultingPhone}}</p>
      </div>
      <div class="copy-box">
        <span @click="copyPhone" class="copy-btn">点击复制微信号码</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'BuyConsultative',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods:{
    onClose() {
      this.$emit('onClose', false)
    },
    
    copyPhone(){
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {//区分iPhone设备
        window.getSelection().removeAllRanges();//这段代码必须放在前面否则无效
        var Url2=document.getElementById("copy");//要复制文字的节点
        var range = document.createRange();
        // 选中需要复制的节点
        range.selectNode(Url2);
        // 执行选中元素
        window.getSelection().addRange(range);
        // 执行 copy 操作
        var successful = document.execCommand('copy');

        // 移除选中的元素
        window.getSelection().removeAllRanges();
        try { 
          const msg = document.execCommand('copy') ? '复制成功' : '复制失败' ;
          this.$toast('复制成功');
          //alert(msg)
          this.onClose()
        } catch (err) { 
          alert('unable to copy', err) 
        }
      }else{
        var text = document.getElementById("copy").innerText;
        const textarea = document.createElement("textarea") ;
        textarea.style.position = 'fixed' ;
        textarea.style.top = 0 ;
        textarea.style.left = 0 ;
        textarea.style.border = 'none' ;
        textarea.style.outline = 'none' ;
        textarea.style.resize = 'none';
        textarea.style.background = 'transparent' ;
        textarea.style.color = 'transparent';
        textarea.value = text ;// 修改文本框的内容
        document.body.appendChild(textarea);
        textarea.select()// 选中文本
        try { 
          const msg = document.execCommand('copy') ? '复制成功' : '复制失败' ;
          this.$toast(msg);
          //alert(msg)
          this.onClose()
        } catch (err) { 
          alert('unable to copy', err) 
        }
        document.body.removeChild(textarea)
      }
    }
  }
}
</script>
<style scoped>
.teacher-box{
  position: absolute;
  width: 80%;
  top:21%;
  left:10%;
  padding:2% 0;
  background:#fff;
  border-radius: 10px;
}
.title{
  font-size: 18px;
  color:#333;
  font-weight: bold;
  padding:0.25rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom:1px solid #ebebeb;
}
.icon-box img{
  width:0.36rem;
  height:0.37rem;
  margin-top:0.1rem;
  margin-right:0.1rem;
}
.close{
  width:0.3rem;
  height:0.3rem;
  position: absolute;
  right: 0.3rem;
  top:0.1rem;
}
.close img{
  width:100%;
  height: 100%;
}
.consultative{
  margin: 0.2rem 0;
  text-align: center;
}
.consultative img{
  width:4rem;
  height:4rem;
  /* margin:0.2rem auto; */
}
.consultative p,.consultative span{
  font-size: 16px;
  line-height: 0.6rem;
}
.consultative .phone{
  display:flex;
}
.consultative .phone p{
  -webkit-user-select: auto;
}
.phone{
  display: flex;
  line-height: 0.6rem;
  align-items: center;
  justify-content: center;
}
.copy-box{
  height:0.7rem;
  line-height: 0.7rem;
  display: flex;
  justify-content: center;
  margin-top: 0.15rem;
}
.copy-box span{
  width:4rem;
  height:0.7rem;
  line-height: 0.7rem;
  background:#FF3F47;
  color:#fff;
  font-size: 16px;
  border-radius: 10px;
}
</style>