<template>
  <div class="m-course">
    <div class="item" v-for="(item, index) of list" :key="index" @click="itemClick(item)">
      <p class="free-tag" >赠送课程</p>
      <!-- <img src="@/assets/images/h5-icon/tag.png" alt="赠送" class="free-tag"> -->
      <div class="title">{{item.courseGroupName}}</div>
      <div class="tip">{{item.subtitle}}</div>
      <div class="tip tip-space">{{item.classTime}}</div>
      <div class="teacher-list">
        <div class="t-itam" v-for="(titem, tindex) of item.teachers" :key="tindex">
          <img :src="titem.headPortrait" :alt="titem.teacherName">
          <p>{{titem.teacherName}}</p>
        </div>
      </div>
      <div class="price">￥{{item.currentPrice}}</div>
    </div>
  </div>
</template>

<script>
import { getCourseGroupCover } from '@/api/api'
export default {
  name: 'MatchingCourse',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    if(this.detail.relationCourseGroupId) {
      this.getCourseGroupCover(this.detail.relationCourseGroupId)
    }
  },
  methods: {
    async getCourseGroupCover(val = '') {
      let params = {
        ids:val
      }
      let response = await getCourseGroupCover(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.list = response.data
    },
    itemClick(item) {
      this.$router.push({path: `/course/${item.id}`})
      this.$nextTick(() => {
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-course{
  padding: 0px 0.4rem;
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 100%;
    margin:0.4rem 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding:0.58rem 0.3rem 0 0.3rem;
    text-align: left;
    position: relative;
    cursor: pointer;
    .free-tag{
      position: absolute;
      width:2rem;
      height: 0.49rem;
      line-height: 0.49rem;
      font-size: 12px;
      text-align: center;
      color: #fff;
      top:0;
      left: 0.3rem;
      background: url('../../assets/images/h5-icon/tag.png') no-repeat;
      background-size: contain;
      user-select: none;
    }
    .title{
      line-height: 0.5rem;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.15rem;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .tip{
      font-size: 14px;
      height: 0.35rem;
      line-height: 0.35rem;
      font-weight: 400;
      color: #5B6B73;
      margin-bottom: 0.1rem;
    }

    .tip-space{
      margin-bottom:0.2rem;
    }
    .teacher-list{
      display: flex;
      height: 1.2rem;
      .t-itam{
        margin: 0 0.15rem 0.2rem 0;
        img{
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          overflow: hidden;
          background: #eee;
          display: block;
          margin-bottom: 0.1rem;
        }
        p{
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 0.3rem;
          text-align: center;
        }
      }
    }
    .price{
      height: 0.7rem;
      border-top: 1px solid #f2f2f5;
      text-align: right;
      font-size: 18px;
      font-weight: bold;
      color: #FF3F47;
      line-height: 0.7rem;
    }
  }
}
</style>