<template>
  <div class="mini">
    <!-- 标题 -->
    <div class="head">
      <div class="left" @click="showArea = true">
        <van-icon size="15" name="location" />
        <span class="area">浙江</span>
        <van-icon class="arrow" size="10" name="play" />
      </div>
      <div class="right" v-if="!getUserInfo" @click="toLogin()">登录/注册</div>
      <div class="user-info" v-if="getUserInfo" @click="mineClick()">
        <img :src="getUserInfo.headImg">
        <p class="user-tel">{{getUserInfo.name}}</p>
        <span>我的</span>
      </div>
    </div>
    <!-- 主体 -->
    <div class="mini-body">
      <!-- 面试 -->
      <div class="part">
        <div class="mb-title text-blod">
          <span class="text-red">面试</span>
          <span class="text-black text-space">课程</span>
          <span class="text-gray">INTERVIEW</span>
        </div>
        <div class="item" v-for="(item, index) of msList" :key="index">
          <div class="item-contain">
            <van-image class="item-cover" width="2.4rem" height="1.6rem" src="https://bx-gwy-platform.oss-cn-hangzhou.aliyuncs.com/%E6%9C%AA%E6%A0%87%E9%A2%98-1.png" />
            <div class="item-desc">
              <div class="item-title">{{item.courseGroupName}}</div>
              <div class="item-sub-title">{{item.classTime}}</div>
              <div class="item-space">
                <span class="price">￥{{item.currentPrice}}</span>
                <span>{{ btnStateComputed(item).state | btnstateFilterSubName}}</span>
                <div class="btn" @click="toBuy(item)" v-if="btnStateComputed(item).canClick">{{btnStateComputed(item).state | btnstateFilterName}}</div>
                <div class="btn btn-gray" v-if="!btnStateComputed(item).canClick">{{btnStateComputed(item).state | btnstateFilterName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 笔试 -->
      <div class="part">
        <div class="mb-title text-blod">
          <span class="text-red">笔试</span>
          <span class="text-black text-space">课程</span>
          <span class="text-gray">WRITTEN EXAMINATION</span>
        </div>
        <div class="item" v-for="(item, index) of bsList" :key="index">
          <div class="item-contain">
            <van-image class="item-cover" width="2.4rem" height="1.6rem" src="https://bx-gwy-platform.oss-cn-hangzhou.aliyuncs.com/2.png" />
            <div class="item-desc">
              <div class="item-title">{{item.courseGroupName}}</div>
              <div class="item-sub-title">{{item.classTime}}</div>
              <div class="item-space">
                <span class="price">￥{{item.currentPrice}}</span>
                <span>{{ btnStateComputed(item).state | btnstateFilterSubName}}</span>
                <div class="btn" @click="toBuy(item)" v-if="btnStateComputed(item).canClick">{{btnStateComputed(item).state | btnstateFilterName}}</div>
                <div class="btn btn-gray" v-if="!btnStateComputed(item).canClick">{{btnStateComputed(item).state | btnstateFilterName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showArea" position="bottom">
      <van-picker
        title="请选择地区"
        show-toolbar
        :columns="columns"
        @confirm="showArea = fasle"
        @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { 
  getCourseGroup,
  getCourseGroupDetail,
  checkBeforeCreate,
  getLessonGroupTips
} from '@/api/api'
import { MINIBTNSTATEFILTER } from '@/config/config'


export default {
  name: 'mini',
  data() {
    return {
      page: 1,
      limit: 50,
      bsList: [],
      msList: [],
      detail: null,
      showArea: false,
      columns: ['浙江'],
    }
  },
  async mounted() {
    this.bsList = await this.getCourseGroup('笔试')
    this.msList = await this.getCourseGroup('面试')
    
  },
  computed: {
    btnStateComputed() {
      return (item) => {
        return MINIBTNSTATEFILTER(item)
      }
    },
    getUserInfo() {
      return this.$store.state.userInfo || null
    }
  },
  filters: {
    btnstateFilterName(val) {
      return val.indexOf('|') !== -1 ? val.split('|')[0] : val
    },
    btnstateFilterSubName(val) {
      return val.indexOf('|') !== -1 ? val.split('|')[1] : ""
    },
  },
  methods: {
    mineClick(val) {
      this.$router.push({path: '/mine'})
    },
    toLogin(){
      this.$store.dispatch('toggleDialog',{isShow: true, type:0})
    },
    async getLessonGroupTips(id) {
      let params = {
        courseGroupId: id
      }
      let response = await getLessonGroupTips(params)
      if(+response.returnCode !== 10001) {
        return null
      }
      
      return response.data.orderId
    },
    async getCourseGroupDetail (id) {
      let response = await getCourseGroupDetail({id: id})
      if(+response.returnCode !== 10001) {
        return null
      }
      return response.data
      
    },
    async checkBeforeCreate(val) {
      
      let params = {
        courseGroupId: this.detail.id
      }
      let response = await checkBeforeCreate(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      } else {
        return true
      }
      
    },
    async toSingup(val) {
      // 关联职位列表，跳转报名步骤 非关联的，就直接跳支付
      let canBuy = await this.checkBeforeCreate()
      if(!canBuy) {
        return false
      }
      // this.$router.push({path: `/course/signup?step=2`})
      if(+val === 1) {
        this.$router.push({path: `/course/signup`})
      } else {
        this.$router.push({path: `/course/signup?step=2`})
      }
    },
    async toBuy(item) {
      let courseDetail = await this.getCourseGroupDetail(item.id)
      let orderId = await this.getLessonGroupTips(item.id)
      this.detail = courseDetail
      let type = MINIBTNSTATEFILTER(courseDetail).type
      if(type === 0) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          this.$store.dispatch('setOrderInfo',{
            courseGroupId: courseDetail.id,
            courseGroupName: courseDetail.courseGroupName,
            jobListId: courseDetail.jobListId,
            loCourseInfo: courseDetail
          })
          this.toSingup(courseDetail.interviewFlag)
          // this.$nextTick(() => {
          //   this.$emit('confirm',courseDetail.interviewFlag)
          // })
        } else {
          this.$store.dispatch('toggleDialog',{isShow: true, type:0})
        }
      } else {
        this.$router.push({path: `/course/signup?step=3&orderId=${orderId}`})
      }
    },
    async getCourseGroup(type) {
      const params = {
        categoryName: type,
        page: this.page,
        limit: this.limit
      }
     
      let response = await getCourseGroup(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      return response.data
    },
  }
}
</script>

<style lang="less" scoped>
.mini{
  font-size: 14px;
  .head{
    color: #fff;
    height: 0.88rem;
    padding: 0 0.36rem;
    background: #E43635;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      display: flex;
      align-items: center;
      .area{
        margin: 0 0.1rem;
      }
    }
    .arrow{
      transform: rotate(90deg) translateX(0.05rem);
    }
  }
  .mini-body{
    .part{
      padding: 0.4rem 0.36rem 0;
      .mb-title{
        height: 0.32rem;
        line-height: 0.32rem;
        font-size: 15px;
        display: flex;
        &:before{
          display: inline-block;
          content: ' ';
          height: 0.32rem;
          width: 0.05rem;
          background: #E43635;
          border-radius: 0.025rem;
          margin-right: 0.1rem;
        }
      }
      .item{
        border-bottom: 1px solid #E8E8E8;
        padding: 0.27rem 0;
      }
      .item-contain{
        display: flex;
        .item-desc{
          margin-left: 0.18rem;
        }
        .item-cover{
          flex-grow: 0;
          flex-shrink: 0;
        }
        .item-title{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 0.42rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .item-sub-title{
          font-size: 11px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 0.42rem;
        }
        .item-space{
          display: flex;
          justify-content: space-between;
          .price{      
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FF3F47;
            line-height: 0.42rem;
          }
          .btn{
            
            height: 0.42rem;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 0.42rem;
            background: #E43635;
            padding: 0 0.3rem;
            text-align: center;
          }
          .btn-gray{
            background: #999!important;
          }
        }
      }
    }
  }
}
.text-red{
  color: #E43635;
}
.text-black{
  color: #333333;
}
.text-gray{
  color: #F6F6F6;
}
.text-blod{
  font-weight: bold;
}
.text-space{
  margin-right: 0.17rem;
}
.user-info{
  display: flex;
  align-items: center;
}
.user-info img{
  width:0.4rem;
  height:0.4rem;
  border-radius:50%;
}
.user-info p{
  font-size: 14px;
  padding-left: 0.1rem
}
.user-info span{
  padding:0 0.1rem;
  border:1px solid #fff;
  border-radius: 10px;
  margin-left: 0.1rem;
}
</style>